export const environment = {
    production: true,
    instagram_token: 'INSTA_TOKEN',
    stripe_token: 'STRIPE_TOKEN',
    paypal_token: 'PAYPAL_TOKEN',
    payment_url: 'https://test-dohabank.mtf.gateway.mastercard.com',
    payment_merchant_url: 'https://test-dohabank.mtf.gateway.mastercard.com/api/rest/version/74/merchant/TESTDB98327/session',
    payment_id: 'merchant.TESTDB98327:6b52dd7d309ee486a41b6c63deb8dcf1',
    mock: false,
    apiBaseUrl: '',
    apiEndpoint: 'https://qa-admin.megamart.qa',
    facebookAppId: '764143232218461',
    apiUrl: 'https://qa.megamart.qa',
    isSignupEnabled: true,
    qpay_url: 'https://pguat.qcb.gov.qa/qcb-pg/api/gateway/2.0'
  };
